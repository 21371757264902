<template>
  <v-card>
    <v-card-title> Remote Assistance - {{ this.status }} - {{ this.timer }}
      <v-icon medium color="info" @click="restart">
        mdi-restart
      </v-icon>
    </v-card-title>

    <v-card-text>
      <v-slider v-model="sliderValue" :label="`Width: ${sliderValue}px`" :max="1000" :min="350"></v-slider>
      <div class="d-flex justify-center">
        <div id="streaming-container" class="pa-6 pb-12" style="cursor: none">
          <video id="streaming-video" autoplay playsinline :style="`width: ${videoWidth}px;`"></video>
          <div
            style="display:flex; justify-content: space-around; gap:35px; background-color: black; padding: 5px 15px">
            <v-icon color="white" @click="onSystemBarActions('back')">
              mdi-arrow-left-bold
            </v-icon>
            <v-icon small color="white" @click="onSystemBarActions('home')">
              mdi-circle
            </v-icon>
            <v-icon small color="white" @click="onSystemBarActions('recent')">
              mdi-square-rounded
            </v-icon>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { throttle } from '../functions/throttle';

export default {
  data() {
    return {
      dialog: false,
      peerConnection: null,
      dataChannel: null,
      status: '',
      videoElement: null,
      deviceId: null,
      debounceMouseMove: null,
      timer: '',
      timerInstance: undefined,
      videoWidth: 350,
      sliderValue: 350,
      sliderDebounceTimer: null,
      swipeListener: null
    }
  },
  watch: {
    sliderValue(val) {
      clearTimeout(this.sliderDebounceTimer);
      this.sliderDebounceTimer = setTimeout(() => {
        this.videoWidth = val;
      }, 2000)
    }
  },
  created() {
    this.deviceId = this.$route.params.id

    this.$signal.getConnection(() => {
      this.startWebRTCConnection()
    })

    this.$signalHub.$on('RemoteAssistanceAnswer', this.onRemoteAnswer)
    this.$signalHub.$on('RemoteAssistanceCandidate', this.onRemoteCandidate)
  },
  beforeDestroy() {
    this.$signalHub.$off('RemoteAssistanceAnswer', this.onRemoteAnswer)
    this.$signalHub.$off('RemoteAssistanceCandidate', this.onRemoteCandidate)

    this.clearWebRTCConnection();
  },
  methods: {
    restart() {
      this.clearWebRTCConnection();
      setTimeout(() => {
        this.startWebRTCConnection()
      }, 1000)
    },

    async startWebRTCConnection() {
      this.status = "initiating"

      this.videoElement = document.getElementById('streaming-video')
      this.setupEventListeners(document)

      this.peerConnection = new RTCPeerConnection({
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          {
            urls: 'turn:170.64.173.147:3478',
            username: 'softclient1',
            credential: 'softclient1'
          }]
      });

      await this.captureAudio()

      this.dataChannel = this.peerConnection.createDataChannel('control')
      this.dataChannel.onopen = () => {
        this.status = 'Data Channel Opened';
      };

      this.dataChannel.onmessage = (event) => {
        this.status`Data Received:`;
      };

      this.peerConnection.addEventListener('track', this.onTrack);
      this.peerConnection.addEventListener("icecandidate", this.onIceCandidate)
      this.peerConnection.addEventListener("icegatheringstatechange", this.onIceGatheringStateChange)
      this.peerConnection.addEventListener('iceconnectionstatechange', this.onIceConnectionStateChange)

      if (await this.createOffer()) {
        this.startCountdown(120)
      }
    },

    captureAudio() {
      return new Promise((resolve) => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          stream.getTracks().forEach((track) => {
            this.peerConnection.addTrack(track, stream);
          });
          resolve(true)
        }).catch(e => {
          resolve(false)
        })
      })
    },

    async createOffer() {
      try {
        const offer = await this.peerConnection.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true
        });

        await this.peerConnection.setLocalDescription(offer);
        const offerRes = await this.$signal.sendRemoteAssistanceOffer(this.deviceId, offer)
        this.status = offerRes?.success ? "Connection starting" : "Device is Offline"
        return offerRes?.success
      } catch (e) {
        this.status = e
      }
    },

    onTrack(event) {
      this.status = "Processing Image"
      const stream = event.streams[0];

      if (this.videoElement) {
        this.videoElement.srcObject = stream;
      }
    },

    onIceGatheringStateChange() {
      if (this.peerConnection.iceGatheringState === 'gathering') {
        this.status = "gathering"
      } else if (this.peerConnection.iceGatheringState === 'complete') {
        this.status = "gathering complete"
      }
    },

    onIceConnectionStateChange() {
      if (
        this.peerConnection.iceConnectionState === "disconnected" ||
        this.peerConnection.iceConnectionState === "failed"
      ) {
        this.status = "disconnected or failed"
        this.clearWebRTCConnection();
      }
    },

    onIceCandidate(event) {
      if (!event.candidate) {
        return
      }

      this.status = "getting ice candidates"
      this.$signal.sendRemoteAssistanceCandidate(this.deviceId, event.candidate);
    },

    async onRemoteAnswer(answer) {
      console.log('onRemoteAnswer');

      await this.peerConnection.setRemoteDescription(answer);
      this.status = "Connected"
    },

    async onRemoteCandidate(candidate) {
      if (!this.peerConnection || !this.peerConnection.remoteDescription) {
        console.log('onRemoteCandidate: skipped');
        return
      }

      this.status = "remote candidate"
      await this.peerConnection.addIceCandidate(candidate);
    },

    async clearWebRTCConnection() {
      this.status = "closing"

      if (this.dataChannel) {
        this.dataChannel.close()
        this.dataChannel = null
      }

      if (this.peerConnection) {
        this.peerConnection.removeEventListener('track', this.onTrack);
        this.peerConnection.removeEventListener("icecandidate", this.onIceCandidate)
        this.peerConnection.removeEventListener("icegatheringstatechange", this.onIceGatheringStateChange)
        this.peerConnection.removeEventListener('iceconnectionstatechange', this.onIceConnectionStateChange)

        this.peerConnection.close();
        this.peerConnection = null;
      }

      if (this.videoElement) {
        this.removeEventListener(document)

        const stream = this.videoElement.srcObject;
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
        }

        this.videoElement.srcObject = null;
        this.videoElement.load();
      }

      this.status = "disconnected"
    },

    setupEventListeners(el) {
      this.debounceMouseMove = throttle(this.onMove, 1.5)
      el.addEventListener('mousemove', this.debounceMouseMove)
      el.addEventListener('keydown', this.onKeyDown)

      this.swipeListener = this.swipeGestureListener(el, (direction) => {
        if (!this.isFocused()) {
          return
        }

        if (!(this.dataChannel && this.dataChannel.readyState === 'open')) {
          return
        }

        if (!direction) {
          return this.dataChannel.send(JSON.stringify({
            type: "click",
          }))
        }

        this.dataChannel.send(JSON.stringify({
          type: "swipe",
          direction
        }));
      })
    },

    removeEventListener(el) {
      el.removeEventListener('mousemove', this.debounceMouseMove)
      el.removeEventListener('keydown', this.onKeyDown)
      this.debounceMouseMove = null

      if (this.swipeListener) {
        this.swipeListener.clear()
      }
    },

    isFocused() {
      return this.videoElement != null && (document.activeElement === this.videoElement || document.activeElement.contains(this.videoElement));
    },

    onKeyDown(e) {
      if (!this.isFocused()) {
        return
      }

      if (this.dataChannel && this.dataChannel.readyState === 'open') {
        this.dataChannel.send(JSON.stringify({
          type: "key",
          key: e.key
        }));
      }
    },

    onMove(e) {
      if (!this.isFocused()) {
        return
      }

      const x = e.clientX;
      const y = e.clientY;

      const targetRect = e.target.getBoundingClientRect();
      const targetWidth = targetRect.width
      const targetHeight = targetRect.height
      const targetTop = targetRect.top + window.scrollY;
      const targetLeft = targetRect.left + window.scrollX;

      const _x = x - targetLeft;
      const _y = y - targetTop;

      if (this.dataChannel && this.dataChannel.readyState === 'open') {
        this.dataChannel.send(JSON.stringify({
          type: "move",
          x: _x,
          y: _y,
          w: targetWidth,
          h: targetHeight
        }));
      }
    },
    startCountdown(time) {
      let remainingTime = time;
      clearInterval(this.timerInstance)

      this.timerInstance = setInterval(() => {
        if (remainingTime <= 0) {
          clearInterval(this.timerInstance);
          this.timer = ''
          return
        }

        this.timer = `${remainingTime}`
        remainingTime--;
      }, 1000);
    },
    swipeGestureListener(swipeArea, listener) {
      let startX, startY, endX, endY;

      function captureStartingCoordinates(e) {
        startX = e.clientX;
        startY = e.clientY;
      }

      function captureEndingCoordinates(e) {
        endX = e.clientX;
        endY = e.clientY;

        detectSwipeDirection(e);
      }

      function detectSwipeDirection(e) {
        const diffX = endX - startX;
        const diffY = endY - startY;

        if (!(Math.abs(diffX) > 5 || Math.abs(diffY) > 5)) {
          listener()
          return
        }

        // Check if the movement is more horizontal or vertical
        if (Math.abs(diffX) > Math.abs(diffY)) {
          // Horizontal swipe
          if (diffX > 0) {
            listener('right')
          } else {
            listener('left')
          }
        } else {
          if (diffY > 0) {
            listener('down')
          } else {
            listener('up')
          }
        }
      }

      const onScroll = throttle((e) => {
        e.preventDefault()

        if (e.deltaY > 0) {
          listener('up')
        } else {
          listener('down')
        }
      }, 10)

      swipeArea.addEventListener('mousedown', captureStartingCoordinates);
      swipeArea.addEventListener('mouseup', captureEndingCoordinates);
      swipeArea.addEventListener('wheel', onScroll, { passive: false })

      return {
        clear: () => {
          swipeArea.removeEventListener('mousedown', captureStartingCoordinates);
          swipeArea.removeEventListener('mouseup', captureEndingCoordinates);
          swipeArea.removeEventListener('wheel', onScroll)
        }
      }
    },
    onSystemBarActions(key) {
      if (!(this.dataChannel && this.dataChannel.readyState === 'open')) {
        return
      }

      this.dataChannel.send(JSON.stringify({
        type: "system-bar-actions",
        key
      }))
    },
  },
}
</script>
